<template>
	<div id="page404">
		<div class="content-404">
			<div class="kd-back">
				<a class='kd-back-txt' :underline="false" @click="$router.go(-1)"> 返 回 </a>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
	}
</script>

<style lang="scss">
	body{
		margin: 0;
		padding: 0;
	}
	.content-404{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		background: #eaeaea;
		text-align: center;
		background-size: 100% 100%;
		background-image: url(../../assets/img/404.jpg);

		img{
			width: 300px;
			height: 200px;
			margin-top: 12%;
		}
	}
	.kd-back{
		display: block;
		position: absolute;
		top: calc(65% - 20px);
		left: calc(50% - 50px);
		width: 100px;
		height: 40px;
		color: #FFF;
		font-size: 14px;
		text-decoration: none;
		text-align: center;
		line-height: 40px;
		border-radius: 2px;
		border: 0px #CCC solid;
		background-color:rgba(255,255,255,0.3);
		
		&-txt{
			color: #FFFFFF !important;
		}
	}
	.kd-back-txt:hover{color: #DDD !important;}
</style>
