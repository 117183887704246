 let siteinfo={
	// siteroot:'http://farm.test.farmkd.com/index.php'
	siteroot:'https://farmdev.farmkd.com/index.php'
	// siteroot:'https://farm.farmkd.com/index.php'
}
/**
 * 注意注意注意
 * 1.打包时检查国标设备播放端口是否修改成线上端口
 */
export default {
	siteinfo
}
