import {reactive} from "vue";
import commonModel from "@/api/common";
import tool from "@/util/tool";

//图片库
export function useImageLibrary(context,multiple){
    let ilState = reactive({
        list:[],
        page:1,
        limit:32,
        count:0,
        size:null,
        category:"all",
        input_url:"",   //单文件
        input_urls:[],  //多文件
        show:false,
        bullInIcon:[],
    })

    function showImage(){
        if( ilState.list.length === 0 ){
            getImage(1,ilState.limit)
        }
        ilState.show = true
    }

    function getImage(page,limit){
        let param = {}
        if( ilState.category === 'in' ){
            getBuiltInIcon()
            return
        }
        if( ilState.category !== 'all' && ilState.category !== 'in'){
            param.class_id = ilState.category
        }
        commonModel.getImageList(page,limit,param,res=>{
            ilState.list = res.list
            ilState.page = res.page
            ilState.limit = limit
            ilState.count = res.count
            ilState.size = res.size
        })
    }

    function checkAll(e) {
        if( ilState.category ==='in' ){
            ilState.bullInIcon.map((item, index) => ilState.bullInIcon[index].checked = e.target.checked )
        }else{
            ilState.list.map((item, index) => ilState.list[index].checked = e.target.checked )
        }
    }

    const checkImg = (index)=>{
        if( ilState.category ==='in' ){
            ilState.bullInIcon[index].checked = !ilState.bullInIcon[index].checked
        }else{
            ilState.list[index].checked = !ilState.list[index].checked
        }

    }

    function deleteImg() {
        let ids = []
        ilState.list.forEach((item)=>item.checked ? ids.push(item.id):"" )
        if( ids.length === 0 ){
            tool.message("请选择要删除的文件!",'warning')
            return;
        }
        commonModel.deleteDataList(ids,28,'确认要删除选中文件吗？').then(()=>{
            tool.message("删除成功")
            getImage(ilState.page,ilState.limit)
        })
    }

    function imageSureCheck(){
        let urls= []
        if( ilState.category === 'in'){
            ilState.bullInIcon.forEach((item)=>{
                if( item.checked ) urls.push(item.url)
            })
        }else{
            ilState.list.forEach((item)=>{
                if( item.checked ) urls.push(item.url)
            })
        }

        if( urls.length === 0 ){
            tool.message("请选择文件",'warning')
            return
        }

        let ret = multiple ? urls : urls[urls.length-1]
        if( multiple ){
            ilState.input_urls = ilState.input_urls.concat(ret)
            context.emit("success",ilState.input_urls)
        }else{
            ilState.input_url = ret
            context.emit("success",ret)
        }

        ilState.show = false
        ilState.list.forEach((item)=>item.checked = false)
    }

    const deleteSelectImg = (index)=>ilState.input_urls.splice(index,1)

    function getBuiltInIcon() {
        let arr = [];
        for (let i = 0; i < 9; i++) {
            let url = tool.getStaticSrc('adopt/a-' + (i + 1) + '.png');
            arr.push({ url, checked: false });
        }
        for (let i = 0; i < 10; i++) {
            let url = tool.getStaticSrc('adopt/b-' + (i + 1) + '.png');
            arr.push({ url, checked: false });
        }
        for (let i = 0; i < 55; i++) {
            let url = tool.getStaticSrc('adopt/c-' + (i + 1) + '.png');
            arr.push({ url, checked: false });
        }
        ilState.bullInIcon = arr;
    }

    function changeImgCategory(cate){
        ilState.category = cate
        getImage(1,ilState.limit)
    }

    return { ilState ,getImage,checkImg,checkAll,deleteImg,imageSureCheck,deleteSelectImg,showImage,changeImgCategory }
}

//图片分类
export function useImageCate(){
    let icState = reactive({
        list:[],
        form:{},
        show:false,
        class_id:null
    })
    getImageCate()
    function getImageCate(){
        commonModel.getImageCate(res=>icState.list = res)
    }
    function showImageCateAdd(data){
        icState.form = {
            id:data?data.id:0,
            name:data ? data.name :''
        }
        icState.show = true
    }

    function saveImageCate(){
        commonModel.createImageCate(JSON.parse(JSON.stringify(icState.form)),()=> {
            icState.show = false
            getImageCate()
        })
    }

    function deleteImageCate(id){
        commonModel.createImageCate({id,status:0},()=>getImageCate())
    }

    function removeImageToCate(ids,cb){
        commonModel.moveImageToCate({class_id:icState.class_id,file_id:ids},res=>cb(res) )
    }


    return { icState ,showImageCateAdd,saveImageCate,deleteImageCate,removeImageToCate}
}
