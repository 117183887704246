<template>
<div class="pager">
    <div>
        <slot></slot>
    </div>
    <a-pagination
        :show-quick-jumper="showJumper"
        :show-size-changer="showSize"
        :default-current="pageData.page"
        :total="pageData.count"
        :page-size="pageData.limit"
        @showSizeChange="sizeChange"
        @change="pageChange"
    />
</div>
</template>

<script>
export default {
    name: "kd-pager",
    props:{
        pageData:{
            type:Object
        },
        event:{
            type:Function
        },
        showSize:{
            type:Boolean,
            default:true
        },
        showJumper:{
            type:Boolean,
            default:false
        }
    },
    setup(props){
        function sizeChange(page,limit){
            if( typeof props.event == 'function' ){
                props.event(page,limit)
            }
        }

        function pageChange(page){
            if( typeof props.event == 'function' ){
                props.event(page,props.pageData.limit)
            }
        }

        return { sizeChange,pageChange }
    }
}
</script>

<style scoped lang="scss">
.pager{
    display: flex;
    justify-content: space-between;
}
</style>
