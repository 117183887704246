<template>
    <div class="none-data">
        <img src="@/assets/img/none-data.png" alt="">
        <p>{{title}}</p> 
    </div>
</template>
<script>
export default {
    name:"com-none-data",
    props:{
        title:{
            type:String,
            default:'暂无数据'
        }
    },
    setup() {
        
    },
}
</script>
<style scoped lang="scss">
.none-data{
    width: 100%;
    text-align: center;

    img{
        width: 100px;
    }
    p{
        margin:12px 0;
        color: #999;
    }
}
</style>