<template>
<div class="image-library">
    <div v-if="custom" @click="showImage">
        <slot></slot>
    </div>
    <template v-else>
        <a-input v-model:value="ilState.input_url" :disabled="mutil">
            <template #addonAfter>
                <span style="cursor: pointer;" @click="showImage">选择图片</span>
            </template>
        </a-input>
        <div class="image-library-one" v-if="!mutil && ilState.input_url">
            <a-image width="100px" :src="ilState.input_url" ></a-image>
        </div>
        <div v-if="mutil && ilState.input_urls && ilState.input_urls.length > 0">
            <draggable v-model="ilState.input_urls" item-key="index" animation="500" :component-data="{class:'image-library-more'}" @change="changeSort">
                <template #item="{element,index}">
                    <div class="ilm-item item">
                        <div class="delete" @click="deleteSelectImg(index)"><i class="ri-delete-bin-2-line"></i></div>
                        <a-image width="100px" :src="element" ></a-image>
                    </div>
                </template>
            </draggable>
        </div>
    </template>

    <a-modal v-model:visible="ilState.show" width="1000px">
        <template #title>
            <span style="margin-right: 12px;">选择文件</span>
            <span class="f12" v-if="ilState.size">存储空间：{{ ilState.size.use_size }}/{{ ilState.size.max_size }}</span>
        </template>
        <a-space class="il-header">
            <a-checkbox @change="checkAll"></a-checkbox>
            <a-button type="danger"  @click="deleteImg">删除</a-button>
            <com-upload-btn @success="getImage(1,ilState.limit)" :multiple="true"></com-upload-btn>
            <a-button @click="showImageCateAdd(null)">增加分类</a-button>
            <a-select v-model:value="icState.class_id"
                      style="width: 180px"
                      @change="moveImageToCate"
                      placeholder="移动到分类">
                <a-select-option :value="0">移动到分类</a-select-option>
                <a-select-option v-for="(item,index) in icState.list" :key="index" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
        </a-space>
        <a-row>
            <a-col :span="4">
                <div class="il-left">
                    <div class="il-left-item"
                         :class="ilState.category === 'all' ?'active':''"
                         @click="changeImgCategory('all')">
                        全部图片
                    </div>
                    <div class="il-left-item"
                         :class="ilState.category === 'in' ?'active':''"
                         @click="changeImgCategory('in')">
                        内置图标
                    </div>
                    <div class="il-left-item"
                         :class="ilState.category === item.id ?'active':''"
                         @click="changeImgCategory(item.id)"
                         v-for="(item,index) in icState.list" :key="index"
                    >
                        {{ item.name }}
                        <div class="il-left-edit">
                            <span @click.stop="showImageCateAdd(item)">编辑</span>
                            <span @click.stop="deleteImageCate(item.id)">删除</span>
                        </div>
                    </div>
                </div>
            </a-col>
            <a-col :span="20">
                <div class="il-right" v-if="ilState.category === 'in'">
                    <div class="il-right-image" v-for="(item,index) in ilState.bullInIcon" :key="item.id" @click="checkImg(index)">
                        <div class="gou" v-if="item.checked"></div>
                        <a-image :preview="false" :width="40" :src="item.url"/>
                    </div>
                    <div style="width: 90px;height: 1px" v-for="item in 7" :key="item"></div>
                </div>
                <div class="il-right" v-else>
                    <div class="il-right-image" v-for="(item,index) in ilState.list" :key="item.id" @click="checkImg(index)">
                        <div class="gou" v-if="item.checked"></div>
                        <a-image :preview="false" :width="90" :src="item.url"/>
                    </div>
                    <div style="width: 90px;height: 1px" v-for="item in 7" :key="item"></div>
                </div>
            </a-col>
        </a-row>

        <template #footer>
            <div class="il-footer">
                <div class="pager" style="text-align: center;margin-bottom: 12px;height: 50px" v-if="ilState.category !== 'in'">
                    <a-pagination
                        show-quick-jumper
                        v-model:current="ilState.page"
                        v-model:pageSize="ilState.limit"
                        :total="ilState.count"
                        @showSizeChange="(p,e)=>{getImage(ilState.page,e)}"
                        @change="(e)=>getImage(e,ilState.limit)"
                    />
                </div>
                <div class="image-library-btn">
                    <a-button @click="ilState.show = false">取消</a-button>
                    <a-button type="primary" @click="imageSureCheck">确认</a-button>
                </div>
            </div>
        </template>
    </a-modal>

    <a-modal title="添加图片分类" v-model:visible="icState.show" width="500px" @ok="saveImageCate" :z-index="99999">
        <a-form :lab-col="{span:4}" :warpper-col="{span:16}">
            <a-form-item label="分类名称">
                <a-input v-model:value="icState.form.name"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
</div>
</template>

<script>
import {ref, watch} from 'vue'
import { useImageLibrary,useImageCate } from '@/models/common'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import draggable from 'vuedraggable'
import tool from "@/util/tool";
export default {
    name: "image-library",
    components:{
        comUploadBtn,
        draggable
    },
    props:{
        mutil: {
            type:Boolean,
            default:false,
        },
        custom:{
            type:Boolean,
            default:false,
        },
        src:{
            type:[String,Array],
        }
    },
    setup(props,context){
        let {
            ilState ,getImage,checkImg,checkAll,deleteImg,imageSureCheck,deleteSelectImg,showImage,changeImgCategory
        } = useImageLibrary(context,props.mutil)

        let { icState,showImageCateAdd,saveImageCate,deleteImageCate,removeImageToCate } = useImageCate()

        function initData(value){
            props.mutil ? ( ilState.input_urls = value ? value:[]) : (  ilState.input_url = value )
        }
        if( props.src ) initData(props.src)

        watch(()=>props.src,value => {
            initData(value)
        },{deep:true})

        watch(()=>ilState.input_url,value=>{
            context.emit("success",value)
        },{deep:true})

        //图片拖动排序
        function changeSort(){
            context.emit("success",ilState.input_urls)
        }

        //获取要移动的图片
        function moveImageToCate(){
            let ids = []
            if( ilState.category === 'in'){
                tool.message("内置图片不支持分类！",'warning')
                return
            }
            ilState.list.forEach(item=>{
                if( item.checked ){
                    ids.push(item.id)
                }
            })
            if( !icState.class_id ) return;

            if( ids.length === 0){
                tool.message("请选择要移动的文件!","warning")
                return;
            }

            removeImageToCate(ids,()=>{
                getImage(ilState.page,ilState.limit)
            })
        }

        return {
            ilState ,getImage,checkImg,checkAll,deleteImg,imageSureCheck,deleteSelectImg,showImage,changeImgCategory,changeSort,
            icState,showImageCateAdd,saveImageCate,deleteImageCate,moveImageToCate
        }
    }
}
</script>

<style scoped lang="scss">
.il-footer{
    .pager{
        position: absolute;
        margin-top: 0;
    }
}

.image-library-one{
    width: 100px;
    height: 100px;
    border: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
}
.image-library-more{
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .ilm-item{
        width: 100px;
        height: 100px;
        border: 1px solid #f4f4f4;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 8px 8px 0;
        position: relative;

        .delete{
            position: absolute;
            width: 100px;
            height: 100px;
            color: #fff;
            background: rgba(0,0,0,.4);
            text-align: center;
            z-index: 9;
            line-height: 100px;
            display: none;

            i{
                font-size: 20px;
            }
        }

        &:hover .delete{
            display: block;
        }
    }
}

.il-header{
    display: flex;
    justify-content:flex-end;
    position: relative;
    top: -12px;
}
.il-left{
    width:100%;
    height:400px;
    background: #fff;
    border: 1px solid #f4f4f4;
    overflow: hidden;
    overflow-y: auto;

    &-edit{
        position: absolute;
        z-index: 9999;
        top: 0;
        height: 20px;
        font-size: 12px;
        background: rgba(#000,.4);
        line-height: 20px;
        display: none;
        span{
            margin-left: 4px;
        }
    }

    &-item{
        height:48px;
        line-height: 48px;
        text-align:center;
        border-bottom:1px solid #f4f4f4;
        cursor: pointer;
        transition: all .6s;
        position: relative;

        &:hover{
            color: #46a6ff;
            border-bottom: 1px solid #46a6ff;

            .il-left-edit{
                display: block;
                color: #fff;
            }
        }
    }

    .active{
        background: #46a6ff;
        color: #fff;
        border-bottom: 1px solid #46a6ff;
    }
}
.il-right{
    height: 410px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    justify-content: space-between;
    align-content: flex-start;
    overflow: hidden;
    overflow-y: auto;

    &-image{
        width:90px;
        height: 90px;
        border: 1px solid #f4f4f4;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 12px;
        transition: all .5s;
        position: relative;
        justify-content: center;
        overflow: hidden;
        &:hover{
            border: 1px solid #46a6ff;
            box-shadow: 1px 0px 10px #cde1f5;
        }

        .gou{
            position: absolute;
            background-color: rgba(0, 0, 0, 0.4);
            width:90px;
            height: 90px;
            background-image: url('/static/img/select.png');
            background-size: 40px 40px;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 99;
        }
    }
}
</style>
