<template>
	<div>
		<!-- <div class="el-content" v-if="!$store.state.hasPerm">
			<span>该模块暂未开通权限</span>
			<p>请联系开发者进行购买！</p>
			<p>联系电话：18723237733   <span style="margin-left: 20px;">QQ ：876891350</span></p>
		</div> -->
		<div id="widsdomFarm">
			<router-view v-slot="{ Component }">
				<keep-alive :include="$store.state.keepPage">
					<component class="view" :is="Component" />
				</keep-alive>
			</router-view>
		</div>
		
	</div>
</template>

<script>
export default{

}
</script>

<style>
</style>