<template>
    <a-upload name="file" :showUploadList="false" :multiple="multiple" :action="actionUrl" @change="uploadChange">
        <a-button :type="btnType" :loading="upload">{{ upload_text }}</a-button>
    </a-upload>
</template>
<script>

import { reactive, toRefs } from 'vue'
import { $post } from '@/api/http'
import tool from '@/util/tool'
import siteinfo from '@/util/global'

export default {
    name:'com-upload-btn',
    props:{
        customUpload:{  //是否自定义上传
            type:Boolean,
            default:false
        },
        uploadType:{    //1上传文件 2上传视频
            type:Number,
            default:1
        },
        btnText:{
            type:String,
            default:'上传文件'
        },
        multiple:{  //多图上传
            type:Boolean,
            default:false
        },
        btnType:{
            type:String,
            default:"primary"
        }
    },
    setup(props,context) {
        const data = reactive({
            upload:false,
            upload_text:props.btnText,
            actionUrl:siteinfo.siteinfo.siteroot+'/web/upload?token='+localStorage.getItem('token'),
        })

        //文件上传响应
        function uploadChange(info){
            if (info.file.status !== 'uploading') {
				data.upload_text = '正在上传...';
				data.upload = true;
			}
			if (info.file.status === 'done') {
                if( info.file.response.data ){
                    //自定义上传返回选择的上传信息
                    if( props.customUpload ){
                        context.emit("uploadChange",info.file.response.data)
                        updateText()
                    }else{
                        $post('createFileUrl', { url: info.file.response.data,type:props.uploadType }).then((res) => {
                            if( res.errorCode == 0 ){
                                tool.message('上传成功');
                                context.emit("success")
                                updateText()
                            }else{
                                updateText(res.data.message)
                            }
                        }).catch(() => {});
                    }
                    return
                }
                updateText(info.file.response.message)
			} else if (info.file.status === 'error') {
				updateText("文件上传失败")
			}
        }

        //更新上传信息
        function updateText(error=""){
            if( error ) tool.message(error,"error")
            data.upload_text = props.btnText;
			data.upload = false;
        }

        return{
            ...toRefs(data),
            uploadChange
        }
    },
}
</script>
