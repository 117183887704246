//提示:title 等于ruoter中的name,	name等于router中的path 便于与后端对接数据

const perm =[
	{ title: "总览",path: "/storeapp", components: "miniIndex",menu_icon: "el-icon-s-operation",is_menu: 1 ,children:[]},
	{title:'商品', path:'',menu_icon:'el-icon-goods',children:[
			{title:'商品列表',path:'/many_store/goods/goodsList',menu_icon:'',children:[]},
			{title:'商品服务',path:'/many_store/goods/serviceList',menu_icon:'',children:[]},
			{title:'运费规则',path:'/many_store/frieght/list',menu_icon:'',children:[]},
			{ title:'订单列表',path:'/many_store/order/orderList',menu_icon:'',children:[]},
			{ title:'售后订单',path:'/many_store/order/sale',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'监控',menu_icon:'el-icon-video-camera-solid',children:[
			{title:'监控列表',path:'/many_store/live/liveList',menu_icon:'',children:[]},
			{title:'监控预览',path:'/many_store/live/live_preview',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'认养',menu_icon:'el-icon-grape',children:[
			{title:'认养列表',path:'/many_store/adopt/list',menu_icon:'',children:[]},
			{ title:'订单管理',path:'/many_store/adopt/orderList',menu_icon:'',children:[]},
			{ title:'认养管理',path:'/many_store/adopt/manager',menu_icon:'',children:[]},
			{ title:'配送订单',path:'/many_store/adopt/delivery_order',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'土地',menu_icon:'el-icon-s-grid',children:[
			{ title:'土地列表',path:'/many_store/land/landList',menu_icon:'',children:[]},
			{ title:'种子列表',path:'/many_store/land/seedList',menu_icon:'',children:[]},
			{ title:'租地订单',path:'/many_store/land/landOrder',menu_icon:'',children:[]},
			{ title:'种植订单',path:'/many_store/land/orderList',menu_icon:'',children:[]},
			{ title:'配送订单',path:'/many_store/land/delivery',menu_icon:'',children:[]},
			{ title:'服务管理',path:'/many_store/land/service',menu_icon:'',children:[]},
			{ title:'操作工单',path:'/many_store/land/workOrder',menu_icon:'',children:[]},
			{ title:'巡检员',path:'/many_store/land/listPolling',menu_icon:'',children:[]},
			{ title:'交换订单',path:'/many_store/land/change',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'营销',menu_icon:'el-icon-star-on',children:[
			{ title:'优惠券',path:'/many_store/marketing/couponList',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'资金',menu_icon:'el-icon-tickets',children:[
			{ title:'提现',path:'/many_store/fund/withdraw',menu_icon:'',children:[]},
			{ title:'资金总览',path:'/many_store/fund/pandect',menu_icon:'',children:[]},
			{ title:'账单明细',path:'/many_store/fund/bill',menu_icon:'',children:[]},
			{ title:'交易明细',path:'/many_store/fund/fundRecord',menu_icon:'',children:[]},
		]
	},
	{path:'',title:'订货',menu_icon:'el-icon-tickets',children:[
			{ title:'进货',path:'/many_store/buy/index',menu_icon:'',children:[]},
		]
	}
]

export default{
	perm,
}
