<template>
    <div class="tinymce-box">
        <editor :id="uniid" v-model="myValue" :init="init"> </editor>
        <kd-img-select ref="imgSelect" :mutil="true" :custom="true" @success="getImages"></kd-img-select>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'

// 引入富文本编辑器主题的js和css
import 'tinymce/skins/content/default/content.css'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default/icons' // 解决了icons.js 报错Unexpected token '<'

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import { reactive, toRefs ,ref, watch} from 'vue'
export default {
    components: {
        Editor
    },
    name: 'tinymce-editor',
    props: {
        modelValue:{
            type:String,
            default:""
        },
        uniid:{
            type:String,
            default:"tinymceSelector"
        },
        // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
        // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
        baseUrl: {
            type: String,
            default: window.location.origin ? window.location.origin : ''
        },
        plugins: {
            type: [String, Array],
            default: 'link lists image code table wordcount media preview fullscreen help'
        },
        toolbar: {
            type: [String, Array],
            default: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor  choose-img media| alignleft aligncenter alignright alignjustify | bullist numlist |outdent indent blockquote | undo redo | link unlink | removeformat'
        }
    },
    setup(props,context){
        const imgSelect = ref()  //eleTable是页面ref后面对应的名字
        const state = reactive({
            editorObj:null,
            init: {
                language_url: `${props.baseUrl}/static/js/tinymce/langs/zh_CN.js`,
                language: 'zh_CN',
                skin_url: `${props.baseUrl}/static/js/tinymce/skins/ui/oxide`,
                convert_urls: false,
                height: 560,
                // （指定需加载的插件）
                plugins: props.plugins,
                toolbar: props.toolbar, // （自定义工具栏）

                statusbar: true, // 底部的状态栏
                menubar: 'file edit insert view format table tools help', // （1级菜单）最上方的菜单
                branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
                //自定义图片上传按钮
                setup: function (editor) {
                    editor.ui.registry.addButton('choose-img',{
                        icon: 'gallery',
                        onAction: (_) => {
                            imgSelect.value.showImage()
                            state.editorObj = editor  //插入图片时需要使用
                        },
                    })
                }
            },
            myValue: props.modelValue
        })

        tinymce.init({})

        watch(()=>props.modelValue,val=>state.myValue = val)

        watch(()=>state.myValue,val=> {
            context.emit('update:modelValue',val)
            context.emit('change',val)
        })


        function getImages(e){

            console.log('====e',e);

            e.forEach(item=>state.editorObj.execCommand('mceInsertContent', false, '<img alt="Smiley face" src="' + item + '"/>'))
        }

        return{
            ...toRefs(state),
            getImages,
            imgSelect
        }
    },
}

</script>
