<template>
    <div class="kd-picker-color" v-clickoutside="handleClose">
        <a-input v-model:value="val" @change="updateValue">
            <!-- <template #addonAfter>
                <colorPicker :value="val" @color="updateColor"></colorPicker>
            </template> -->

            <template #addonAfter>
                <div class="pc-color-box" :style="{background:val}" @click="showColorPancel"></div>
            </template>
        </a-input>
        <div class="kd-picker-box">
            <div class="kd-picker-item" v-if="showColor" :style="{position:fixd ?'fixed':'absolute'}">
                <div class="pi-left">
                    <div class="pi-btn">
                        <div class="pi-btn-color" :style="{background:hoverColor ?hoverColor:val}"></div>
                        <div class="pi-btn-select" @click="showHtml5Pancel">更多颜色</div>
                        <input type="color"
                            ref="html5Color"
                            v-model="val"
                            @change="updateValue"
                        />

                    </div>
                    <div class="title">主题颜色</div>
                    <ul class="tColor">
                        <li
                            v-for="(color, index) of tColor"
                            :key="index" 
                            :style="{ backgroundColor: color }"
                            @mouseover="hoverColor = color"
                            @mouseout="hoverColor = null"
                            @click="selectColor(color)"
                        ></li>
                    </ul>
                    <ul class="bColor">
                        <li v-for="(item, index) of colorPanel" :key="index">
                            <ul>
                            <li
                                v-for="(color, cindex) of item"
                                :key="cindex"
                                :style="{ backgroundColor: color }"
                                @mouseover="hoverColor = color"
                                @mouseout="hoverColor = null"
                                @click="selectColor(color)"
                            ></li>
                            </ul>
                        </li>
                    </ul>
                    <div class="title">标准颜色</div>
                    <ul class="tColor">
                        <li
                            v-for="(color, index) of bColor"
                            :key="index"
                            :style="{ backgroundColor: color }"
                            @mouseover="hoverColor = color"
                            @mouseout="hoverColor = null"
                            @click="selectColor(color)"
                        ></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, defineComponent,nextTick,reactive,ref ,toRefs,watch} from 'vue'
import colorPicker from '@/components/public/com-color-picker.vue'
export default defineComponent({
    name:'kd-color',
    components:{
        colorPicker,
    },
    props:{
        modelValue:{
            type:String,
            default:"",
        },
        fixd:{
            type:Boolean,
            default:false
        },
    },
    setup(props,context) {
        const val = ref(props.modelValue)
        const html5Color = ref()
        const state = reactive({
            showColor:false,
            html5ColorValue:'',
            hoverColor:null,
            tColor: [
                "#000000",
                "#ffffff",
                "#eeece1",
                "#1e497b",
                "#4e81bb",
                "#e2534d",
                "#9aba60",
                "#8165a0",
                "#47acc5",
                "#f9974c",
            ],
            colorConfig: [
                ["#7f7f7f", "#f2f2f2"],
                ["#0d0d0d", "#808080"],
                ["#1c1a10", "#ddd8c3"],
                ["#0e243d", "#c6d9f0"],
                ["#233f5e", "#dae5f0"],
                ["#632623", "#f2dbdb"],
                ["#4d602c", "#eaf1de"],
                ["#3f3150", "#e6e0ec"],
                ["#1e5867", "#d9eef3"],
                ["#99490f", "#fee9da"],
            ],
            // 标准颜色
            bColor: [
                "#c21401",
                "#ff1e02",
                "#ffc12a",
                "#ffff3a",
                "#90cf5b",
                "#00af57",
                "#00afee",
                "#0071be",
                "#00215f",
                "#72349d",
            ]
        })

            
        //监听当前绑定的值的数据变化 主要用户初始化监听v-model传入的值
        watch(() => props.modelValue,(value) => {
                val.value  = value
            }
        )
        const updateValue = (e) => {
            state.showColor = false
            context.emit('update:modelValue',val.value)
            context.emit("getVal",val.value)
        }
        const updateColor = (e)=>{
            val.value = e
            context.emit('update:modelValue',e)
            context.emit("getVal",val.value)
        }

        function showHtml5Pancel(){
            console.log('html5Color',html5Color);
            
            html5Color.value.click()
        }

        function gradient(startColor, endColor, step) {
            // 讲 hex 转换为 rgb
            let sColor = hexToRgb(startColor);
            let eColor = hexToRgb(endColor);
            // 计算R\G\B每一步的差值
            let rStep = (eColor[0] - sColor[0]) / step;
            let gStep = (eColor[1] - sColor[1]) / step;
            let bStep = (eColor[2] - sColor[2]) / step;
            let gradientColorArr = [];
            // 计算每一步的hex值
            for (let i = 0; i < step; i++) {
                gradientColorArr.push(
                rgbToHex(
                    parseInt(rStep * i + sColor[0]),
                    parseInt(gStep * i + sColor[1]),
                    parseInt(bStep * i + sColor[2])
                )
                );
            }
            return gradientColorArr;
        }

        function hexToRgb(hex) {
            hex = parseColor(hex);
            let rgb = [];
            for (let i = 1; i < 7; i += 2) {
                rgb.push(parseInt("0x" + hex.slice(i, i + 2)));
            }
            return rgb;
        }

        function rgbToHex(r, g, b) {
            let hex = ((r << 16) | (g << 8) | b).toString(16);
            return "#" + new Array(Math.abs(hex.length - 7)).join("0") + hex;
        }
        // 格式化 hex 颜色值
        function parseColor(hexStr) {
            if (hexStr.length === 4) {
                hexStr =
                "#" +
                hexStr[1] +
                hexStr[1] +
                hexStr[2] +
                hexStr[2] +
                hexStr[3] +
                hexStr[3];
            } else {
                return hexStr;
            }
        }

        const colorPanel = computed(()=>{
             let colorArr = [];
            for (let color of state.colorConfig) {
                colorArr.push(gradient(color[1], color[0], 5));
            }
            console.log('clo',colorArr);
            
            return colorArr;
        })

        const selectColor = color=>{
            val.value = color
            state.showColor = false
            context.emit('update:modelValue',color)
            context.emit("getVal",val.value)
        }

        function handleClose(){
            state.showColor = false
        }

        function showColorPancel(e){
            state.showColor = true
            var eleTop = document.querySelector(".kd-picker-box").getBoundingClientRect();
            console.log('eleTop',eleTop);
        }

        return{
            ...toRefs(state),
            val,
            updateValue,
            updateColor,
            colorPanel,
            html5Color,
            selectColor,
            showHtml5Pancel,
            handleClose,
            showColorPancel
        }
    },
})
</script>

<style lang="scss" scoped>
.kd-picker-color{
    width: 100%;
    position: relative;
}
.pc-color-box{
    width: 30px;
    height: 20px;
}
.kd-picker-box{
    width: 300px;
    position: absolute;
    right: 0;
}

.kd-picker-item{
    width: 300px;
    height: 330px;
    display: flex;
    z-index: 999;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #e0e0e0;
    ul{
        margin: 0;
        padding: 0;
    }
    .pi-left{
        width: 280px;
        margin-left: 10px;
        padding: 8px;

        
        input {
            visibility: hidden;
            position: absolute;
        }

        .title{
            font-size: 14px;
            color: #666;
            height: 40px;
            line-height: 40px;
        }
        .pi-btn{
            display: flex;
            
            >div{
                width: 100px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
                margin-right: 12px;
            }

            &-select{
                border: 1px solid #f4f4f4;
            }

            &-color{
                transition: all 0.3s ease;
            }
        }

        .tColor {
            li {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 6px;
                transition: all 0.3s ease;
            }
            li:hover {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
                transform: scale(1.3);
            }
        }
        .bColor {
            li {
                width: 20px;
                display: inline-block;
                margin-right: 6px;
                li {
                    display: block;
                    width: 20px;
                    height: 20px;
                    transition: all 0.3s ease;
                    margin: 0;
                }
                li:hover {
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
                    transform: scale(1.3);
                }
            }
        }
    }
    
}

</style>
