<template>
	<!-- 自定义图标按钮 -->
	<div class="com-kd-btn">
		<span v-if="custom">
			<template v-if="title">
				<a-tooltip :title="title">
					<slot></slot>
				</a-tooltip>
			</template>
			<template v-else>
				<slot></slot>
			</template>
		</span>
		<span v-else>
			<template v-if="title">
				<a-tooltip :title="title">
					<div class="kd-btn" :class="type" @click="clickThis">
						<i :class="icon" class="ri-lg ri-top"></i>
					</div>
				</a-tooltip>
			</template>
			<template v-else>
				<div class="kd-btn" :class="type" @click="clickThis">
					<i :class="icon" class="ri-lg ri-top"></i>
				</div>
			</template>
		</span>
	</div>
</template>

<script>
	export default{
		name:'kd-button',
		data(){
			return{
				
			}
		},
		props:{
			type:{
				type:String,
				default:'primary'
			},
			icon:{
				type:String
			},
			title:{
				type:String
			},
			custom:{
				type:Boolean,
				default:false
			}
		},
		methods:{
			clickThis(){
				this.$emit('change')
			}
		}
	}
</script>

<style lang="scss">
	.com-kd-btn{
		display: inline-block;
        padding-right: 8px;
        
		.kd-btn{
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #00CC66;
			text-align: center;
			line-height: 32px;
			cursor: pointer;
		}
		.primary{
			background: rgba($color: #0099FF, $alpha: .2);
			color: #0099FF;
		}
		.success{
			background: rgba($color: #00CC66, $alpha: .2);
			color: #00CC66;
		}
		.danger{
			background: rgba($color: #FF3366, $alpha: .2);
			color: #FF3366;
		}
		.purple{
			background: rgba($color: #6666FF, $alpha: .2);
			color: #6666FF;
		}
		.black{
			background: rgba($color: #000033, $alpha: .2);
			color: #000033;
		}
		
	}
	
	
	
</style>
