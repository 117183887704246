<template>
	<div class="kd-map" style="display:inline-block;">
		<div v-if="custom" @click="showMap" >
			<slot></slot>
		</div>
		<div v-else style="width:100%">
			<a-input v-model:value="lnglat" disabled>
				<template #addonAfter>
					<div @click="showMap" style="cursor: pointer;">选择地址</div>
				</template>
			</a-input>
		</div>
		<a-modal centered v-model:visible="show.map" title="选择地址" @ok="getAddressInfo" width="900px" @cancel="show.map = false">
			<div class="kdmp-search-box">
				<a-input class="kdmp-search-input" placeholder="搜索地址" v-model:value="keyword" id="input_id"></a-input>
			</div>
			<div id="container" style="width:850px; height:400px" ></div>
		</a-modal>
	</div>
</template>

<script>
import GdMap from '@/util/GdMap'
import { nextTick, reactive, toRefs, watch } from 'vue'
export default{
	name:'kd-map',
	props:{
		value:{
			type:String,
		},
		custom:{
			type:Boolean,
			default:false
		}
	},
	
	setup(props,context){
		const state = reactive({
			map:null,
			keyword:"",
			lnglat:'',
			show:{
				map:false
			}
		})

		watch(() => props.value,(value) => {
            if( value ){
                let str = value.split(",")
                if( str[0] && str[0] !='null' && str[0] !='undefined' && str[1] && str[1] !='null' && str[1] !='undefined' ){
                    state.lnglat = value
                }
            }
        },{deep:true,immediate:true})

		function showMap(){
			state.show.map = true
			nextTick(()=>{
				let map= new GdMap({el:'container',drawType:'location'})
				let la = null
				if( props.value !="" && props.value && props.value!="," ){
					let str = props.value.split(",")
					if( str[0] && str[0] !='null' && str[0] !='undefined' && str[1] && str[1] !='null' && str[1] !='undefined' ){
						la = str
					}
				}
				map.initMap(true,la)
				map.searchMap("input_id")
				state.map = map
			})
		}

		function getAddressInfo(){
			state.map.getSelectAddress(res=>{
				state.lnglat = res.longitude+','+res.latitude
				state.show.map = false
				context.emit("change",res)
			})
		}

		return {
			...toRefs(state),
			showMap,
			getAddressInfo
		}
	}
}
</script>

<style lang="scss">
	.kdmp-search-input{
		width: 300px;
		position: absolute;
		z-index: 999;
		margin-top: 12px;
		margin-left: 12px;
	}
</style>
