<template>
	<!-- 隐藏敏感信息的输入框 -->
	<div class="components-hide-senditivity-input">
		<div v-if="!update && value">
			<a-input :value="hideStr(value)" disabled>
				<template #addonAfter>
					<span style="cursor: pointer;" v-if="!disabled && isUpdate" @click="update = true" >修改</span>
				</template>
			</a-input>
		</div>
		<div v-else>
			<a-input v-model:value="val" @change="getValue"></a-input>
		</div>
	</div>
</template>

<script>
	export default{
		name:'kd-hide-sensitivity-input',
		data(){
			return{
				val:'',
				update:false
			}
		},
		props:{
			value:{
				type:String,
				default:''
			},
			disabled:{
				type:Boolean,
				default:false,
			},
			isUpdate:{				//是否允许被修改 默认值:true
				type:Boolean,
				default:true
			}
		},
		methods:{
			getValue(e){
				if( typeof e =='string') this.$emit('getValue',e)
				if( typeof e == 'object') this.$emit('getValue',e.target.value)
			},
			hideStr(str){
				if(str.length >1 ){
					return str.replace(/^(.{4}).*(.{4})$/,"$1************************$2")
				}else{
					return str+'************************'
				}
			},
		}
	}
</script>

<style>
</style>
