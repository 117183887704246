import siteinfo from './global.js'
class Status{
	constructor() {}
	
	/**
	 * 获取土地订单状态
	 * @param {Object} data 土地订单信息
	 * @param {Boolean} color 返回状态颜色信息
	 */
	getLandOrderStatus(data,color=false){
		let c = data.is_cancel
		let r = data.is_refund
		let p = data.pay_status
		let l = data.is_plant
		let s = data.is_send
		let m = data.is_confirm
		
		if( c == 1 ) return color ? "#999" :'已取消'
		if( r == 2 ) return color ? "#FF3366" :'已退款'
		if( p == 0 ) return color ? "#999" :'未支付'
		if( p == 1 && m == 1 ) return color ? "#00CC66" : '已完成'
		if( p == 1 && s == 0 && m == 0 && l == 0 ) return color ? "#0099FF" :'已支付'
		if( l == 1 && s == 0 ) return "#00CC66" ? 5 :'种植中'
		if( s == 1 ) return color ? "#00CC66" :'已发货'
	}
	
	/**
	 * 判断土地租赁订单某个操作是否可操作
	 * @param {Object} data	订单信息
	 * @param {Object} operation 操作类型
	 */
	getLandOrderOperation(data,operation){
		let c = data.is_cancel
		let r = data.is_refund
		let p = data.pay_status
		let l = data.is_plant
		let s = data.is_send
		let m = data.is_confirm
		
		
		if( operation == 'cancel' ){
			return  ( p == 0 && c == 0 && r != 2 && m == 0) ? true :false
		}
		if( operation == 'refund' ){
			return ( r !=2 && p == 1 && c ==0 && m==0) ? true :false
		}
		if( operation == 'stop' ){
			return ( p ==1 && r !=2 && c ==0 && m==0) ? true :false
		}
	}
	
	/**
	 * 获取种植列表的状态
	 * @param {Object} plant	种植信息
	 * @param {Object} code		获取状态码
	 */
	getPlantStatus(plant,code = false){
		let s = plant.plant_status
		let r = plant.is_ripe
		let c = plant.is_complete
		if( plant.withered == 1 ) return code ? 4 : "已枯萎"
		if( c == 1) return code ? 5 :"已完成"
		if( s == 0 || s ==null ) return code ? 0 : '待播种'
		if( s == 1 && plant.is_seedling == 0 ) return code ? 1 :"已播种"
		if( plant.is_seedling == 1 && !plant.is_grow  && !plant.is_ripe ) return code ? 1 : "幼苗期"
		if( plant.is_grow == 1  && !plant.is_ripe ) return code ? 2 : "生长期"
		if( plant.is_ripe == 1 ) return code ? 3 : "成熟期"
	}
	
	getPlantStatusBg(plant){
		let code = this.getPlantStatus(plant,true)
		if( code == 0 ) return '#999'
		if( code == 1 ) return '#3399FF'
		if( code == 2 ) return '#FF9933'
		if( code == 3 ) return '#FF0066'
		if( code == 5 ) return '#00CC66'
	}
	
	/**
	 * 获取种植订单状态信息
	 * @param {data} 订单信息
	 * @param {code} 是否返回状态码  
	 */
	getPlantOrderStatus(data,code = false){
		if( data.is_refund == 2 && data.pay_status == 1) return code ? 0 : '已退款'
		if( data.is_cancel == 1 ) return code ? 1 :'已取消'
		if( data.pay_status == 1 && data.is_confirm == 0 ) return code ? 2 :'已支付'
		if( data.pay_status == 1 && data.is_confirm == 1 ) return code ? 4 :'已完成'
		if( data.pay_status == 0 ) return code ? 3 :'未支付'
	}
	
	/**
	 * 获取种植订单状态展示标签背景
	 * @param {row} 订单信息
	 */
	getPlantOrderStatusBg(row){
		let c = this.getPlantOrderStatus(row,true)
		if( c== 0 || c== 1 ||c == 3) return '#FF9933'
		if( c== 3 ) return '#FF3366'
		if( c==2 ) return '#00CC66'
	}
	
	/**
	 * 获取配送订单状态
	 * @param {row} 订单信息
	 * @param {type} 
	 * @param {bg} 是否返回背景信息  
	 */
	getSendOrderStatus(data,type="pay",bg = false){
		if( type== 'pay' ){
			if( data.pay_status == 1 && data.is_refund == 2 ) return bg ? '#FF9933' : '已退款'
			if( data.is_cancel == 1) return bg ? '#999' :'已取消' 
			if( data.pay_status == 1 ) return bg ? '#00CC66' :'已支付' 
			if( data.pay_status == 0 ) return bg ? '#FF0066' :'未支付' 
		}
		
		if( type == 'send'){
			let d = data.delivery_status , run = data.runing
			
			//订单状态(待接单＝1,待取货＝2,配送中＝3,已完成＝4,已取消＝5, 指派单=8,妥投异常之物品返回中=9, 妥投异常之物品返回完成=10, 骑士到店=100,创建达达运单失败=1000
			if( run && run.run_type == 'dada' ){
				if( run.order_status == 1 ) return '待接单'
				if( run.order_status == 2 ) return '待取货'
				if( run.order_status == 3 ) return '配送中'
				if( run.order_status == 4 ) return '达达已完成'
				if( run.order_status == 5 ) return '达达已取消'
				if( run.order_status == 8 ) return '指派单'
				if( run.order_status == 9 ) return '妥投异常，物品返回中'
				if( run.order_status == 10 ) return '妥投异常，物品返回完成'
				if( run.order_status == 100 ) return '骑士到店'
				if( run.order_status == 1000 ) return '创建达达运单失败'
				return '未知状态'
			}
			
			
			if( d == 0 ) return '待发货'
			if( d == 1 ) return '已发货'
			if( d == 2 ) return '已完成'
		}
	}
	
	/**
	 * 获取积分商城订单状态信息
	 * @param {Object} order 订单信息
	 * @param {Boolean} color 是否返回展示颜色  
	 */
	getIntegralOrderStatus(order,color=false){
		let p = order.pay_status
		let c = order.is_cancel
		let s = order.is_send
		let m = order.is_confirm
		let r = order.is_refund
		if( p ==0 ) return color ? '#ff0000':'待支付'
		if( c == 1) return color ? '#999':'已取消'
		if( p == 1 ){
			if( r == 1 ) return color? '#ff0000':'申请退款'
			if( r == 2 ) return color? '#ff0000':'已退款'
			if( r == 3 ) return color? '#ff0000':'退款已拒绝'
			if( m == 1 ) return color? '#0ab90a':'交易成功'
			if( s == 1 ) return color? '#0ab90a':'已发货'
			
			return color? '#00aaff':'待发货' 
		}
	}
	
	/**
	 * 获取积分商城订单操作按钮权限
	 * @param {Object} order
	 * @param {Object} type
	 */
	getIntegralOrderBtnAuth(order,type){
		let p = order.pay_status
		let c = order.is_cancel
		let s = order.is_send
		let m = order.is_confirm
		let r = order.is_refund
		if( order.exchange_type == 1 ){
			if( type == 'send'){
				return p == 1 && s == 0 && m == 0 ? true :false
			}
			if( type == 'confirm'){
				return p == 1 && s == 1 && m == 0 ? true :false
			}
		}
	}
	
	
	/**
	 * 获取商城订单状态描述 
	 * @param {order} 订单信息
	 * @param {color} true:返回状态展示颜色  false:返回状态描述
	 */
	getShopOrderStaus(order,color = false){
		let s = order.order_status 		//订单状态
		let c = order.is_cancel			//订单取消状态
		let r = order.recovery_method	//订单配送方式 1快递 2自提
		let f = order.is_refund			//退款状态
		let run = order.runing
		
		//订单状态(待接单＝1,待取货＝2,配送中＝3,已完成＝4,已取消＝5, 指派单=8,妥投异常之物品返回中=9, 妥投异常之物品返回完成=10, 骑士到店=100,创建达达运单失败=1000
		if( run && run.run_type == 'dada' ){
			if( run.order_status == 1 ) return color ? '#00aaff' :'待接单'
			if( run.order_status == 2 ) return color ? '#00aaff' :'待取货'
			if( run.order_status == 3 ) return color ? '#0ab90a' :'配送中'
			if( run.order_status == 4 ) return color ? '#0ab90a' :'已完成'
			if( run.order_status == 5 ) return color ? '#999' :'已取消'
			if( run.order_status == 8 ) return color ? '#00aaff' :'指派单'
			if( run.order_status == 9 ) return color ? '#ff0000' :'妥投异常，物品返回中'
			if( run.order_status == 10 ) return color ? '#ff0000' :'妥投异常，物品返回完成'
			if( run.order_status == 100 ) return color ? '#00aaff' :'骑士到店'
			if( run.order_status == 1000 ) return color ? '#ff0000' :'创建达达运单失败'
			return color ? '#999' : '未知状态'
		}
		
		if( c == 1) return color ? '#999' : '订单已取消'
		if( !s && !c ) return color ? '#ff0000' : '待支付'
		if( s == 1 && f == 1 ) return color ? '#ff0000' : '退款申请中'
		if( f == 2 ) return color ? '#ff0000' : '已退款'
		if( s == 1 ) return color ? '#00aaff' : r == 1 ? '等待商家配送' :'待自提'
		if( s == 2 ) return color ? '#0ab90a' : '订单已发货'
		if( s == 3 ) return color ? '#0ab90a' : '交易成功'
		return color ? '#999' : '未知状态'
	}
	
	/**
	 * 获取商城订单操作按钮是否展示
	 * @param {Object} order	订单信息
	 * @param {Object} type		操作类型 移入回收站=recycleBin 移出回收站=recycleBinOut
	 * 							删除=del 发货=delivery 取消订单=cancel 确认收货=confirm 查看物流=logistics
	 */
	getShopOrderBtnAuth(order,type){
		let s = order.order_status 		//订单状态
		let c = order.is_cancel			//订单取消状态
		let r = order.recovery_method	//订单配送方式 1快递 2自提
		let f = order.is_refund			//退款状态
		let run = order.runing
		
		if(run &&  run.run_type == 'dada' ){
			if( [1,2,3,4,100,8].includes(run.order_status) ){
				return false
			}
		}
		if( type == 'recycleBin' ){
			return  ( c == 1 || f == 2 ) && order.is_recycle == 0  ? true :false
		}
		if( type == 'recycleBinOut' || type == 'del' ){
			return order.is_recycle == 1 ? true :false
		}
		if( type == 'delivery' ){
			return  r == 1 && s == 1 && (!f || f == 3) ? true :false
		}
		if( type == 'cancel' ){
			return  !s && !c ? true :false
		}
		if( type == 'confirm' ){
			return s == 2 ? true :false
		}
		
		if( type == 'logistics' ){
			return s >=2 && r == 1 ? true :false
		}
		
		if( type == 'refund' ){
			return s == 1 && !order.is_card && f !=2 ? true :false
		}
	}
	
	/**
	 * 检测配送订单操作按钮是否展示
	 * @param {row} 订单信息
	 * @param {opera} 操作标识  
	 */
	checkSendOrderopera(data,opera){
		let p = data.pay_status
		let c = data.is_cancel
		let f = data.is_free_delivery
		let e = data.is_refund
		let d = data.delivery_status
		
		if( opera == 'cancel' ){
			return ( p == 1 && c == 0 && e !=2 && d == 0 && f == 1) ? true : false
		}
		if( opera == 'send' ){
			if( p == 1 && c == 0 && e !=2 && d ==0  ){
				let run = data.runing
				if(run &&  run.run_type == 'dada' ){
					if( ['1','2','3','4','100','8'].includes(run.order_status) ){
						return false
					}
				}
			}
			
			return ( p == 1 && c == 0 && e !=2 && d ==0 ) ? true :false
		}
		if( opera == 'refund' ){
			return ( p == 1 && c==0 && e !=2 && f == 0 && d == 0 ) ? true :false
		}
	}
	/**
	 * 获取种植订单状态
	 * @param {Object} row
	 */
	getPlantOrderStatusBg(row){
		let c = this.getPlantOrderStatus(row,true)
		if( c== 0 || c== 1 ||c == 3) return '#FF9900'
		if( c== 3 ) return '#FF0066'
		if( c==2 ) return '#00CC66'
	}
	/**
	 * 获取种植订单状态信息
	 * @param {data} 订单信息
	 * @param {code} 是否返回状态码  
	 */
	getPlantOrderStatus(data,code = false){
		if( data.is_refund == 2 && data.pay_status == 1) return code ? 0 : '已退款'
		if( data.is_cancel == 1 ) return code ? 1 :'已取消'
		if( data.pay_status == 1 && data.is_confirm == 0 ) return code ? 2 :'已支付'
		if( data.pay_status == 1 && data.is_confirm == 1 ) return code ? 4 :'已完成'
		if( data.pay_status == 0 ) return code ? 3 :'未支付'
	}
	
	/**
	 * 认养订单状态判断
	 * @param {Object} order	订单详情
	 */
	adoptOrderStatus(order,color=false){
		let p = order.pay_status;
		let r = order.is_refund;
		let c = order.is_cancel;
		let m = order.is_confirm;
		if (p == 1 && r == 1) return color ? "#FF9900" : '申请退款中'
		if (p == 1 && r == 2) return color ? "#FF0066" : '已退款'
		if (p == 0 && c == 1) return color ? "#999" : '订单已取消'
		if (p == 0) return color ? "#999" : '未支付'
		if (p == 1 && m) return color ? "#00CCCC" : '订单已完成'
		if (p == 1) return color ? "#00CC66" : '已支付'
		return  color ? "#000" :'未知状态'
	}
	
	/**
	 * 检测认养订单是否可操作
	 * @param {Object} order	订单信息
	 * @param {Object} opera	操作标识
	 */
	checkAdoptOrderBtn(order,opera){
		//移出回收站
		if( opera == 'outrecycle' ) return (order.is_recycle == 1 ? true :false)
		//移入回收站
		if( opera == 'inrecycle' ) {
			return ((order.is_cancel == 1 || order.is_refund == 2) && order.is_recycle == 0) ?  true :false
		}
		//删除
		if( opera == 'delete' ) return order.is_recycle == 1 ? true :false
		//取消
		if( opera == 'cancel' ) {
			return ( !order.pay_status && !order.is_cancel ) ? true:false
 		}
	}
	
}

const statusModel = new Status()
export default statusModel