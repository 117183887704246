<template>
    <a-button type="primary" :loading="$store.state.subimiting" @click="clickThis">
        {{ $store.state.subimiting ? loadingText: btnText}}
    </a-button>    
</template>
<script>
export default {
    props:{
        btnText:{
            type:String,
            default:'保存'
        },
        loadingText:{
            type:String,
            default:'正在保存...'
        }
    },
    setup(props,context) {
        const clickThis = ()=>context.emit("success")
        return{
            clickThis
        }
    },
}
</script>
