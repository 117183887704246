import axios from 'axios'
import siteinfo from '@/util/global'
import tool from '@/util/tool.js'
import store from '../store'
import router from '../router'
var $http = axios.create({
	baseURL: siteinfo.siteinfo.siteroot,
	responseType: 'json',
	headers: {
		'token': localStorage.getItem("token") || '222',
		'content-type': 'application/json; charset=utf-8;', //转换为key=value的格式必须增加content-type
	}
})

// 添加请求拦截器
$http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers.token = localStorage.getItem("token") || '111'
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
$http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    store.dispatch("setLoading",false)

    setTimeout(() => {
        store.dispatch('setSubimiting', false)
    }, 500);

    return response;
}, function (error) {
    // 对响应错误做点什么
    store.dispatch("setLoading",false)

    setTimeout(() => {
        store.dispatch('setSubimiting', false)
    }, 500);

    return Promise.reject(error);
});

const checkErroeCode = function(code) {
    if( code == 9 ){
        tool.message("登录已过期","error")
        store.dispatch("setToken",null)
        store.dispatch("setUserId",null)
        store.dispatch("setUsername",null)
        store.dispatch('setPermissionList',null)
        localStorage.setItem('token','')
        localStorage.setItem('userId','')
        localStorage.setItem('loginname','')
        router.push('/login')
    }

    //云端未授权
	if (code == 1987) {
        tool.message("云端未授权，请先进行授权登录或注册~","error")
		router.push('/live/kd-cloud-auth')
	}

    //判断该接口用户是否有权限访问(已被注释)
	// if ([21401, 21402].includes(code)) { //无权限
	// 	store.dispatch('setHasPerm', false)
	// } else {
	// 	store.dispatch('setHasPerm', true)
	// }
}

/**
 *
 * @param {String} portName 接口名称
 * @param {Object} data     请求数据
 * @param {Boolean}isMsg    返回的错误信息是否反馈给用户（默认true）
 * @param {Number} rank     请求场景，不用场景API前缀不一样 1sass端 2农场端（默认） 3客户端接口（自定义PC端 特殊需求）
 * @param {boolean} loading 是否显示加载提示
 * @param {boolean} submiting 是否节流
 */
export const $post = function(portName,data,isMsg=true,rank=2,loading=true,submiting=false){
    if( loading ) store.dispatch("setLoading",true)
    if( submiting ) store.dispatch("setSubimiting",true)

    return new Promise((resolve,reject)=>{
        let api = `/api/admin/${portName}`
        if( rank == 1 ) api = `/web/${portName}`
        if( rank == 3 ) api = `/app/${portName}`
        $http.post(api,data).then(res=>{
            checkErroeCode(res.data.errorCode)
            let result = res.data
            if( res.data.errorCode !=0 && isMsg ){
                tool.message(res.data.message,"error")
                reject(result)
                return
            }
            if( res.data.errorCode == 0 ){
                resolve(result)
                return
            }
            reject(result)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const $cloudPost = function(api,data,loading=true,apicustom=false) {
    if( loading ){
        store.dispatch("setLoading",true)
    }
    return new Promise((resolve,reject)=>{
        //云端接口地址
        var actionUrl ="https://cloud.cqkundian.com/index.php/api/min_program/" +api

        if( apicustom ) actionUrl = api //自定义请求地址

        $http.post(actionUrl,data).then(res=>{
            let result = res.data
            if( res.data.code !=0){
                tool.message(res.data.message,"error")
                reject(result)
                return
            }
            if( res.data.code == 0 ){
                resolve(result)
                return
            }
            reject(result)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const logout = function() {
    tool.confirm("确认退出登录吗？").then(()=>{
        store.dispatch("setToken",null)
        store.dispatch("setUserId",null)
        store.dispatch("setUsername",null)
        store.dispatch("setPermissionList",null)
        localStorage.setItem("token","")
        localStorage.setItem("userId","")
        localStorage.setItem("loginname","")

        if( !localStorage.getItem("token") ){
            tool.message("已退出登录")
            router.push("/login")
        }
    }).catch(()=>{})
}
