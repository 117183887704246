<template>
	<div class="upload-cert">
		<a-input v-model:value="url_text" :disabled="disabled" @change="updateValue">
			<template #addonAfter>
				<a-upload
					name="file"
					:showUploadList="false"
					:action="actionUrl"
					@change="uploadChange"
				>
					<i v-if="!loading" style="cursor: pointer;" class="ri-upload-cloud-2-fill"></i>
					<a-spin v-else />
				</a-upload>
			</template>
		</a-input>
	</div>
</template>

<script>
import tool from '@/util/tool';
import siteinfo from '@/util/global'
export default {
	name: 'upload-cert',
	data() {
		return {
			url: '',
			url_text: '',
			actionUrl: siteinfo.siteinfo.siteroot + '/web/upload?is_file=1&token=' + localStorage.getItem('token'),
			loading:false,
		};
	},
	props: {
		value: {
			type: String,
			value: ''
		},
		hideUrl: {
			type: Boolean,
			default: true
		},
		disabled:{
			type:Boolean,
			default:true
		}
	},
	watch: {
		url(val) {
			this.$emit('success', val);
		},
		value(val) {
			this.url = val;
			if (this.hideUrl) {
				if (this.url == '' || !this.url) {
					this.url_text = '未上传';
				} else {
					this.url_text = '已上传';
				}
			} else {
				this.url_text = this.url;
			}
		}
	},
	mounted(){
		if( this.hideUrl){
			if( this.value ){
				this.url_text = "已上传"
			}else{
				this.url_text = '未上传';
			}
		}else{
			this.url_text = this.value
		}
	},

	methods: {
		uploadChange(info) {
			let t = this;
			t.loading =true
			if (info.file.status === 'done') {
				t.url = info.file.response.data
				if (t.hideUrl) {
					t.url_text = '已上传';
				} else {
					t.url_text = t.url;
				}
				tool.message("上传成功")
				t.$emit('success', t.url);
				t.loading = false
			} else if (info.file.status === 'error') {
				tool.message('文件上传失败',"error");
				tool.message("上传失败","error")
				t.loading = false
			}
		},

		updateValue(e){
			console.log('e',e.target.value);
			this.$emit('success', e.target.value);
		}
	}
};
</script>

<style></style>
