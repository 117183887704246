import tool from "./tool"

class GdMap{
    constructor(config){

        //========================
        //  config:{
        //      el：容器
        //      lng：经度
        //      lat：纬度
        //      drawType:绘制类型  polygon=多边形绘制  circle=圆形绘制   location=定位 ,live=监控标记
        //      isSatellite:是否显示卫星图层
        //  }
        //========================
        this.config = config

        this.map = null
        this.drawStatus = 0         //是否开始绘制 0=未开始  1=绘制中
        this.mouseTool = null
        this.polygonEditer=null     //多边形编辑对象
        this.circleEditor=null      //圆编辑对象
        this.locationInfo = {}      //位置选择信息
        this.marker = null          //标记信息
        this.customInfoWindow = null      //自定义信息窗体

        console.log('this.config',this.config);
    }

    /**
     * 初始化地图
     * @param {Boolean} isLocation 是否使用浏览器定位当前位置
     * @param {Array} location          默认位置[116.397428, 39.90923]
     */
    initMap(isLocation,location){
        let _this = this , map = null

        //默认使用中心位置
        if( !isLocation && !location ) location = [116.397428, 39.90923]
        if( _this.config.isSatellite ){
            var tiandituLayer = new AMap.TileLayer.Satellite({})
            //加载天地图的瓦片地址
            tiandituLayer.setTileUrl('http://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX=[z]&TILEROW=[y]&TILECOL=[x]&tk=68404e269217bb006fd5977410da694a')
            map = new AMap.Map('allmap', {
                zoom:_this.config.zoom || 17,//级别
                center: location || [116.397428, 39.90923],//中心点坐标
                viewMode:'3D',//使用3D视图
                layers:[tiandituLayer],
            });
        }else{
            map = new AMap.Map(_this.config.el, {
                zoom:_this.config.zoom || 17,//级别
                center: location || [116.397428, 39.90923],//中心点坐标
                viewMode:'3D'//使用3D视图
            });
        }

        _this.map = map

        if( isLocation  && !location ) _this.initLocation()

        if( _this.config.drawType == 'location' ){
            if( location && location.length  == 2 ){
                _this.addMarker({lng:location[0],lat:location[1]},"")
            }

            map.on('click', function(ev) {
				let pos ={
					lng:ev.lnglat.lng,
					lat:ev.lnglat.lat,
				}
				_this.addMarker(pos,"")
			});
        }
    }

    //初始化获取当前浏览器位置
    initLocation(){
        let _this = this
        AMap.plugin('AMap.Geolocation', function() {
            var geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                timeout: 10000,          //超过10秒后停止定位，默认：5s
                position:'RB',    //定位按钮的停靠位置
                buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点

            });
            _this.map.addControl(geolocation);
            geolocation.getCurrentPosition(function(status,result){
                if(status=='complete'){
                    console.log('定位成功:',result);
                }else{
                    console.log('定位失败:',result);
                }
            });
        });
    }

    /**
     * 设置地图默认中心点
     * @param {Array} lnglat
     * @param {Number} zoom 层级
     */
    setCenter(lnglat,zoom){
        if( zoom ){
            this.map.setZoomAndCenter(zoom,lnglat,false,300)
            return
        }
        this.map.setCenter(lnglat,false,300)
    }


    /**
     * 给地图添加搜索按钮
     * @param {String} el //input id
     */
    searchMap(el){
        let _this = this
        let autoOptions = {
            input: el,// input 为绑定输入提示功能的input的DOM ID
        }
        AMap.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], function(){
            var auto = new AMap.AutoComplete(autoOptions);
            var placeSearch = new AMap.PlaceSearch({
                map: _this.map
            });  //构造地点查询类
            auto.on("select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询
            }
        });
    }


    beginDraw(){
        let _this = this
        _this.drawStatus = 1    //将绘制状态更新为 “绘制中”

        if( _this.config.drawType == 'polygon' ){
            AMap.plugin(["AMap.MouseTool"],function () {
                _this.mouseTool = new AMap.MouseTool(_this.map)
                //多边形采用绘制线段的方式连接起来形成多边形
                _this.mouseTool.polyline({
                    strokeColor: "#3366FF",
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    strokeStyle: "dashed",
                })
            });
        }

        if( _this.config.drawType == 'circle' ){
            AMap.plugin(["AMap.MouseTool"],function () {
                _this.mouseTool = new AMap.MouseTool(_this.map)
            });
        }
    }


    //绘制多边形的点
    drawPolyline(cb){
        let _this = this
        if( this.drawStatus == 0 ){
            let polylinePoint = [],infoWindow = null,allMarker=[]
            _this.beginDraw()

            //地图点击事件，记录并标记点击的点
            _this.map.on("click",function(e) {
                if( _this.drawStatus == 1 ){
                    AMap.plugin(["AMap.CircleMarker"],function () {
                        var marker = new AMap.CircleMarker({
                            center:[e.lnglat.lng,e.lnglat.lat],
                            radius:6,//3D视图下，CircleMarker半径不要超过64px
                            strokeColor:'white',
                            strokeWeight:2,
                            strokeOpacity:0.5,
                            fillColor:'rgba(0,0,255,1)',
                            fillOpacity:1,
                            zIndex:999,
                            bubble:true,
                            cursor:'pointer',
                            clickable: true
                        })
                        marker.setMap(_this.map)
                        allMarker.push(marker)
                        polylinePoint.push([e.lnglat.lng,e.lnglat.lat])
                        //判断最后点击的一个点是不是开始的点
                        marker.on('click',function(){
                            _this.mouseTool.close(true)
                            _this.drawStatus = 2
                            _this.drawPolyon( res=>cb(res),polylinePoint )
                            _this.map.remove(allMarker) //移除所有点标记
                        })
                    })
                }
            })
            _this.map.on("mousemove",function(e) {
                if( _this.drawStatus == 1 ){
                    //构建信息窗体中显示的内容
                    var info = [];
                    if( polylinePoint.length == 0 ){
                        info.push("<div style=\"font-size:12px;\">开始绘制第一个点");
                    }else if(polylinePoint.length == 1 ){
                        info.push("<div style=\"font-size:12px;\">继续绘制下一个点");
                    }else{
                        info.push("<div style=\"font-size:12px;\">继续绘制下一个点或点击起点完成绘制");
                    }
                    infoWindow = new AMap.InfoWindow({
                        content:info.join("<br/>"),//信息窗体的内容
                        anchor: 'top-center',
                        offset:new AMap.Pixel(0, 20)
                    });
                    infoWindow.open(_this.map,[e.lnglat.lng,e.lnglat.lat]); //信息窗体打开
                }else{
                    if( _this.drawStatus == 2 && infoWindow){
                        infoWindow.close()
                    }
                }
            })
        }
    }


    /**
     * 绘制多边形
     * @param {function} cb
     * @param {Array} point         多边形的点
     * @param {Boolean} edit        是否允许编辑
     * @param {String} fillColor    填充颜色
     * @param {String} strokeColor  边颜色
     * @param {String} info         是否有信息窗体展示
     */
    drawPolyon(cb,point,edit=true,fillColor='#ff780a',strokeColor="#2b8cbe",info=null){
        let _this = this
        AMap.plugin(["AMap.Polygon",'AMap.PolygonEditor'],function () {
            let polygonObj = new AMap.Polygon({
                path: point,
                fillColor: fillColor,
                strokeOpacity: 1,
                fillOpacity: 0.3,
                strokeColor: strokeColor,
                strokeWeight: 3,
                strokeStyle: 'sliod',
                strokeDasharray: [5, 5],
            });
            _this.map.add(polygonObj);

            if( info ){
                polygonObj.on("click",function(e){
                    console.log('e',e.lnglat);
                    _this.addWindowInfo(info,[e.lnglat.lng,e.lnglat.lat])
                })
            }


            if( edit ){
                var polygonEditer = new AMap.PolygonEditor(_this.map, polygonObj)
                polygonEditer.open()		//绘制的多边形打开编辑
                _this.polygonEditer = polygonEditer

                //绘制完成，返回绘制的点的坐标和面积
                polygonEditer.on('end', function(event) {
                    if(_this.drawStatus == 2){
                        let markerPonit =[], polyPath = event.target.getPath()
                        polyPath.forEach( item=> markerPonit.push([item.lng,item.lat]) )
                        var area = (AMap.GeometryUtil.ringArea(markerPonit)).toFixed(2);    //单位：平方米
                        let result = {
                            point:markerPonit,
                            area:area
                        }
                        cb(result)
                        // mapClear()
                    }
                })
            }

        })
    }

    //确定圆心
    drawCircleCenter(cb){
        let infoWindow = null ,_this = this
        //鼠标提示
        _this.map.on('mousemove', function(e){
            if( _this.drawStatus == 1 ){
                var info = [];
                info.push("<div style=\"font-size:12px;\">点击地图的上任意一点确定圆心");
                infoWindow = new AMap.InfoWindow({
                    content:info.join("<br/>"),//信息窗体的内容
                    anchor: 'top-center',
                    offset:new AMap.Pixel(0, 20)
                });
                infoWindow.open(_this.map,[e.lnglat.lng,e.lnglat.lat]); //信息窗体打开
            }
            if( _this.drawStatus == 2 ){
                infoWindow.close()
            }
        });

        //使用地图点击事件，确定圆心位置
        _this.map.on("click",function(e){
            if( _this.drawStatus == 1 ){
                cb([e.lnglat.lng, e.lnglat.lat])
            }
        })
    }

    /**
     * 画圆
     * @param cb    回调函数
     * @param point 圆心
     * @param radius半径
     * @param edit  是否为编辑状态
     */
    drawCircle(cb,point,radius=1000,edit=true){
        let _this = this
        if( point && radius ){
            _this.drawStatus = 2
            AMap.plugin(["AMap.CircleEditor","AMap.Circle"],function(){
                var circleObj = new AMap.Circle({
                    center: point,
                    radius: radius, //半径
                    borderWeight: 3,
                    strokeColor: "#FF33FF",
                    strokeWeight: 6,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.4,
                    strokeStyle: 'dashed',
                    strokeDasharray: [10, 10],
                    fillColor: '#1791fc',
                    zIndex: 50,
                })
                circleObj.setMap(_this.map)
                _this.map.setFitView([ circleObj ])

                if( edit ){
                    //编辑圆
                    var circleEditor = new AMap.CircleEditor(_this.map, circleObj)
                    circleEditor.open()
                    circleEditor.on('end', function(event) {
                        //判断一下绘制状态 ，当此时绘制状态等于0时，表示用户需要重新绘制
                        if( _this.drawStatus == 2){
                            let r = event.target.getRadius()
                            let result = {
                                center:event.target.getCenter(),  	//圆心位置
                                radius:r,							//圆半径
                                area:(3.14 * r*r).toFixed(2)	    //圆面积=π*r*r 平方米
                            }
                            _this.clearMap();
                            cb(result)
                        }
                    })
                    _this.circleEditor = circleEditor
                }
            })
        }else{
            _this.beginDraw()
            _this.drawCircleCenter(res=>{
                _this.drawStatus = 2
                AMap.plugin(["AMap.CircleEditor","AMap.Circle"],function(){
                    var circleObj = new AMap.Circle({
                        center: res,
                        radius: radius, //半径
                        borderWeight: 3,
                        strokeColor: "#FF33FF",
                        strokeWeight: 6,
                        strokeOpacity: 0.2,
                        fillOpacity: 0.4,
                        strokeStyle: 'dashed',
                        strokeDasharray: [10, 10],
                        fillColor: '#1791fc',
                        zIndex: 50,
                    })
                    circleObj.setMap(_this.map)
                    _this.map.setFitView([ circleObj ])

                    if( edit ){
                        //编辑圆
                        var circleEditor = new AMap.CircleEditor(_this.map, circleObj)
                        circleEditor.open()
                        circleEditor.on('end', function(event) {
                            //判断一下绘制状态 ，当此时绘制状态等于0时，表示用户需要重新绘制
                            if( _this.drawStatus == 2){
                                let r = event.target.getRadius()
                                let result = {
                                    center:event.target.getCenter(),  	//圆心位置
                                    radius:r,							//圆半径
                                    area:(3.14 * r*r).toFixed(2)	    //圆面积=π*r*r 平方米
                                }
                                _this.clearMap();
                                cb(result)
                            }
                        })
                        _this.circleEditor = circleEditor
                    }
                })
            })
        }
    }


    //绘制完成
    completeDraw(){
        this.drawStatus = 2
        if( this.config.drawType == 'polygon' ) this.polygonEditer.close()
        if( this.config.drawType == 'circle' ) this.circleEditor.close()
    }

    clearMap(){
        this.map.clearMap()
        this.drawStatus = 0
        if( this.circleEditor ) this.circleEditor.close()
        if( this.polygonEditer ) this.polygonEditer.close()
    }

    /**
     * 添加位置标记
     * @param {Object} location
     * @param {String} name     位置名称
     * @param {String} icon     标记自定义图片
     * @param {String} extData  自定义绑定参数
     * @param {Boolean} isClick 是否绑定点击事件
     * @param {function} fn     点击事件回调
     */
    addMarker( location,name,icon,extData,isClick,fn ){
        let _this = this , iconObj = null
        if( _this.marker ){
            _this.marker.setMap(null);
            _this.marker = null;
        }
        //设置标记图标
        if( icon ){
            iconObj = new AMap.Icon({
                size: new AMap.Size(60, 60),    // 图标尺寸
                image: icon,  // Icon的图像
                imageSize: new AMap.Size(60, 60)   // 根据所设置的大小拉伸或压缩图片
            });
        }

        //设置标记
        _this.marker = new AMap.Marker({
            position: new AMap.LngLat(location.lng,location.lat),
            offset: new AMap.Pixel(-10, -10),
            title:name,
            icon:iconObj,
            extData:extData
        });
        _this.marker.setMap(_this.map);

        //绑定标记的点击事件
        if( isClick ){
            _this.marker.on("click",function(e){
                console.log('extData',_this.marker.getExtData());
                fn(_this.marker.getExtData())
            })
        }

        console.log('this.marker',_this.marker);
        _this.addressGeocoder(location)     //定位解析
    }

    addBatchMarker( location, extData, icon, fn){
        let _this = this, iconObj = null ,markers = []
        if( icon ){
            iconObj = new AMap.Icon({
                size: new AMap.Size(60, 60),    // 图标尺寸
                image: icon,  // Icon的图像
                imageSize: new AMap.Size(60, 60)   // 根据所设置的大小拉伸或压缩图片
            });
        }

        location.forEach((v,i)=>{
            markers[i] = new AMap.Marker({
                position: v,
                icon: iconObj,
                offset: new AMap.Pixel(0,0), //设置偏移量
                extData:extData[i]
            });
            _this.map.add(markers[i])
            markers[i].on("click",function(e){
                console.log('extData',markers[i].getExtData());
                fn(markers[i].getExtData())
            })
        })
    }

    /**
     * 根据经纬度解析具体地址信息
     * @param {Object} lnglat
     */
    addressGeocoder(lnglat){
        let _this = this
        _this.locationInfo.longitude = lnglat.lng
        _this.locationInfo.latitude = lnglat.lat
        AMap.plugin('AMap.Geocoder', function() {
            var geocoder = new AMap.Geocoder({})

            geocoder.getAddress(lnglat, function(status, result) {
                if (status === 'complete' && result.info === 'OK') {
                    // result为对应的地理位置详细信息
                    if (result && result.regeocode) {
                        _this.locationInfo.address = result.regeocode.formattedAddress
                        _this.locationInfo.city = result.regeocode.addressComponent.city
                        _this.locationInfo.adcode = result.regeocode.addressComponent.adcode
                        _this.locationInfo.country = result.regeocode.addressComponent.country
                        _this.locationInfo.province = result.regeocode.addressComponent.province
                        _this.locationInfo.district = result.regeocode.addressComponent.district
                        _this.locationInfo.citycode = result.regeocode.addressComponent.citycode
                    }
                    console.log("地址详细信息1",_this.locationInfo);
                }
            })
        })
    }

    /**
     * 获取当前选择的位置信息
     * @param {function} cb
     */
    getSelectAddress(cb){
        let _this = this
        if( _this.locationInfo.latitude == "" || _this.locationInfo.longitude =="" ){
            tool.message("请选择地址","warning")
            return
        }
        cb(_this.locationInfo)
    }

    /**
     * 添加自定义信息窗体
     * @param {String} info     element元素
     * @param {Array} location  位置
     */
    addWindowInfo(info,location){
        this.customInfoWindow = new AMap.InfoWindow({
            isCustom: true,  //使用自定义窗体
            content: info,
            offset: new AMap.Pixel(16, -45)
        });
        this.customInfoWindow.open(this.map,location);
    }

    /**
     * 关闭自定义信息窗体
     */
    closeWindowInfo(){
        this.map.clearInfoWindow();
    }

}

export default GdMap
