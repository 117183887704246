import { $post,$cloudPost } from '@/api/http'
export default class GizRequest{
	constructor() {
	    this.giz = null
	}
	
	init(t){
		let _self= this
		return new Promise((resolve,reject)=>{
			// 正式 http://cloud.cqkundian.com/index.php/api/open/iot_sdk
			$cloudPost("//cloud.cqkundian.com/index.php/api/open/iot_sdk",null,true,true).then(res=>{
				_self.giz = eval(res.data.javascript)
				$post('open_getThingDetail').then(reslut=>{
					//物联网初始化登录操作
					_self.giz.init({
					    host: "wss://m2m.gizwits.com:8880/ws/app/v1",
					    app_id:reslut.data.app_id,
					    uid: reslut.data.gizwits_uid,
					    token:reslut.data.token,
					});
					// _self.giz.cmd('c2s_read',{})
					resolve(true)
				})
			})
		})
	}
	/**
	 * 获取风向
	 * @param {Object} v
	 */
	getWindDirection(v){
		if( v==0 ) return "北风"
		if( v==1 ) return "北东北风"
		if( v==2 ) return "东北风"
		if( v==3 ) return "东东北风"
		if( v==4 ) return "东风"
		if( v==5 ) return "东东南风"
		if( v==6 ) return "东南风"
		if( v==7 ) return "南东风"
		if( v==8 ) return "南风"
		if( v==9 ) return "南西南风"
		if( v==10 ) return "西南风"
		if( v==11 ) return "西西南风"
		if( v==12 ) return "西风"
		if( v==13 ) return "西西北风"
		if( v==14 ) return "西北风"
		if( v==15 ) return "北西北风"
		return "未知"
	}
	
	/**
	 * 获取开关模式
	 * @param {Object} mode
	 */
	getSwitchModeName(mode){
		let str = mode.split(":")
		if( str.length > 0 ){
			if( str[0] == 'time') return "点动模式"
			if( str[0] == 'lock') return "互锁模式"
			if( str[0] == 'delay') return "延时模式"
			if( str[0] == 'switch') return "开关模式"
			return ""
		}
	}
	
	/**
	 * 读值校正信息
	 * @param {Object} index 校正值索引
	 */
	getReadReviseTitle(index){
		index = parseInt(index)
		switch (index){
			case 1:
				return "A1口电压信号校正（1mv）"
				break;
			case 2:
				return "A2口电压信号校正（1mv）"
				break;
			case 3:
				return "A3口电压信号校正（1mv）"
				break;
			case 4:
				return "A4口电压信号校正（1mv）"
				break;
			case 5:
				return "A5口电压信号校正（1mv）"
				break;
			case 6:
				return "D1口读值1校正（精度以传感器精度读值为准）"
				break;
			case 7:
				return "D1口读值2校正（精度以传感器精度读值为准）"
				break;
			case 8:
				return "D1口读值3校正（精度以传感器精度读值为准）"
				break;
			case 9:
				return "D1口读值4校正（精度以传感器精度读值为准）"
				break;
			case 10:
				return "D1口读值5校正（精度以传感器精度读值为准）"
				break;
			case 11:
				return "D2口读值1校正（精度以传感器精度读值为准）"
				break;
			case 12:
				return "D2口读值2校正（精度以传感器精度读值为准）"
				break;
			case 13:
				return "D2口读值3校正（精度以传感器精度读值为准）"
				break;
			case 14:
				return "D2口读值4校正（精度以传感器精度读值为准）"
				break;
			case 15:
				return "D2口读值5校正（精度以传感器精度读值为准）"
				break;
			case 16:
				return "D3口读值1校正（精度以传感器精度读值为准）"
				break;
			case 17:
				return "D3口读值2校正（精度以传感器精度读值为准）"
				break;
			case 18:
				return "D3口读值3校正（精度以传感器精度读值为准）"
				break;
			case 19:
				return "D3口读值4校正（精度以传感器精度读值为准）"
				break;
			case 20:
				return "D3口读值5校正（精度以传感器精度读值为准）"
				break;
			default:
				return "";
				break;
		}
	}
	
	/**
	 * 检测智控是否设置
	 * @param {Object} data
	 */
	checkHasWit(data){
		let flag = false
		for (var i = 0; i < data.length; i++) {
			if( data[i].info && data[i].info !="未设置" ){
				flag = true
				break
			}
		}
		return flag
	}
}