<template>
	<!-- 定义按钮 -->
	<div class="kd-switch">
		<a-switch v-model:checked="checked" @change="changeChecked"/>
	</div>
</template>

<script>
	export default{
		name:'kd-switch',
		data(){
			return{
				checked:false
			}
		},
		props:{
			value:{
				type:[String,Boolean,Number],
				default:0
			},
			activeValue:{	//选中状态的值
				type:[String,Boolean,Number],
				default:1
			},
			inactiveValue:{	//未选中状态的值
				type:[String,Boolean,Number],
				default:0
			}
		},
		watch:{
			value:{
				deep: true,
				immediate: true,
				handler(val){
					//将传入的value值转换成Boolean类型
					if( typeof val == "string" || typeof val =='number'){
						this.checked = val == 1 ? true:false
						return
					}
					this.checked = val
				},
			},
		},
		methods:{
			//当switch的值变化的时候，同步更新传入的值
			changeChecked(e){
				let status = e ? 1 :0
				this.$emit("change",status)
			}
		}
	}
</script>

<style>
</style>
